import axios from 'axios';

const baseUrl = '/api/cpi-opportunities';

export function listCpiOpportunities(queryParams) {
  return axios.get(baseUrl, { params: queryParams });
}

export function getCpiOpportunity(opportunityId) {
  return axios.get(`${baseUrl}/${opportunityId}`);
}

export function getIpAlphaEntities(query) {
  return axios.post(
    `${process.env.VUE_APP_IP_ALPHA_API_URL}/api/opensearch/entities/_search`,
    query
  );
}

export function createCpiOpportunity(opportunity) {
  return axios.post(`${baseUrl}`, opportunity);
}

export function updateCpiOpportunity(opportunityId, opportunity) {
  const opps = JSON.parse(localStorage.getItem('cpiOpportunities'));
  const opp = opps?.selected.find(o => o.id === opportunityId);
  if (opp) {
    Object.assign(opp, opportunity);
    localStorage.setItem('cpiOpportunities', JSON.stringify(opps));
  }

  return axios.put(`${baseUrl}/${opportunityId}`, opportunity);
}

export async function deleteCpiOpportunity(opportunityId) {
  await axios.delete(`${baseUrl}/${opportunityId}`);
}

export async function createOpportunityTask(newTask) {
  const response = await axios.post(`${baseUrl}/task-items`, newTask);
  return response.data;
}
