<template>
  <v-dialog v-model="open" @click:outside="close" @keydown.esc="close">
    <div class="free-text-modal">
      <v-card class="pa-8">
        <h5 class="mb-4">{{ $t(`cpiOpportunity.form.${fieldName}`) }}</h5>
        <v-textarea
          id="free-text"
          v-model="updatedCpiOpportunity[fieldName]"
          class="text-area"
          height="20rem"
          no-resize
          outlined
        />
        <v-row dense>
          <AppButton class="mr-2" :disabled="saving" @click="save">
            {{ $t('cpiOpportunity.form.save') }}
          </AppButton>
          <AppButton
            class="mr-2"
            :disabled="saving"
            variation="ghost"
            @click="$emit('update:open', false)"
          >
            {{ $t('cpiOpportunity.form.cancel') }}
          </AppButton>
        </v-row>
      </v-card>
    </div>
    <Spinner v-if="saving" class="fixed-center" />
  </v-dialog>
</template>

<script>
import { updateCpiOpportunity } from '@/api/cpiOpportunities';

export default {
  name: 'FreeTextModal',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    cpiOpportunity: {
      type: Object,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      text: '',
      updatedCpiOpportunity: '',
    };
  },
  mounted() {
    this.updatedCpiOpportunity = { ...this.cpiOpportunity };

    if (this.updatedCpiOpportunity[this.fieldName]) {
      this.updatedCpiOpportunity[this.fieldName] =
        '\n' + this.updatedCpiOpportunity[this.fieldName];
      const inputElement = document.getElementById('free-text');
      inputElement.focus();
      this.$nextTick(() => inputElement.setSelectionRange(0, 0));
    }
  },
  methods: {
    close() {
      this.$emit('update:open', false);
    },
    async save() {
      this.saving = true;
      try {
        await updateCpiOpportunity(
          this.updatedCpiOpportunity.id,
          this.updatedCpiOpportunity
        );
      } catch (e) {
        this.$AppEventBus.$emit('snacktime', {
          type: 'error',
          message: 'Unable to update Cpi Opportunity',
        });
      }
      this.saving = false;
      this.$emit('update:open', false);
      this.$emit('updated');
    },
  },
};
</script>

<style lang="scss" scoped>
.free-text-modal {
  .text-area {
    width: 30rem;
  }
}
</style>
