<template>
  <div class="cpi-opportunity-list">
    <v-row>
      <v-col class="d-flex align-center pt-10">
        <h4 class="mb-0 aon-gray-01--text">
          {{ $t('cpiOpportunityList.title') }}
        </h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <div>
          <TableFiltersSelect
            :active-filters="activeFilters"
            :filter-types="filterTypes"
            bottom-chips
            chip-titles
            peacock-chips
            :large-chips="true"
            @change="applyFilters"
          />
        </div>
      </v-col>
      <v-col class="text-right">
        <AppButton :disabled="loading" @click="showAddOpportunity = true">
          {{ $t('cpiOpportunity.addOpportunity') }}
        </AppButton>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="cpi-table-container elevation-1">
          <v-data-table
            :headers="cpiOpportunityListHeaders"
            :items="cpiOpportunities"
            :loading="loading"
            :options.sync="pagingOptions"
            multi-sort
            :server-items-length="pageItemsCount"
            :footer-props="{
              'items-per-page-options': [5, 10, 15, 25, 50, 75, 100],
            }"
          >
            <template #[`header.selected`]>
              <v-simple-checkbox
                :ripple="false"
                :value="allSelected"
                color="blue"
                @click="toggleSelectAll"
              />
            </template>
            <template #[`item.selected`]="{ item }">
              <v-simple-checkbox
                :ripple="false"
                :value="selectedOpportunities.some(s => s.id === item.id)"
                @click="
                  $store.dispatch('cpiOpportunities/toggleSelected', item)
                "
              />
            </template>
            <template #[`item.borrowerName`]="{ item }">
              <router-link
                :to="{
                  name: 'CPI Opportunity',
                  params: { id: item.id },
                }"
                class="applicant-name"
              >
                {{ item.borrowerName }}
              </router-link>
            </template>

            <template #[`item.lenderName`]="{ item }">
              {{
                item.lenderName === 'Other'
                  ? item.otherLenderName
                  : item.lenderName
              }}
            </template>
            <template #[`item.loanAmount`]="{item}">
              {{ getLoanAmount(item) }}
            </template>
            <template #[`item.result`]="{ item }">
              <div class="d-flex py-2">
                <v-chip
                  v-if="item.result"
                  :color="`${getStatusColor(item)}-background`"
                  :text-color="`${getStatusColor(item)}-dark`"
                >
                  {{ $t(`cpiOpportunityList.result.${item.result}`) }}
                </v-chip>
              </div>
            </template>
            <template #[`item.nextSteps`]="{item}">
              <v-textarea
                v-model="item.nextSteps"
                class="pt-4 mb-0 hide-scrollbar"
                rows="5"
                readonly
                outlined
                no-resize
                append-icon="fal fa-edit"
                @click="openFreeTextModal(item, 'nextSteps')"
                @click:append="openFreeTextModal(item, 'nextSteps')"
              />
            </template>
            <template #[`item.notes`]="{item}">
              <v-textarea
                v-model="item.notes"
                class="pt-4 pb-0 hide-scrollbar"
                rows="5"
                readonly
                outlined
                no-resize
                append-icon="fal fa-edit"
                @click="openFreeTextModal(item, 'notes')"
                @click:append="openFreeTextModal(item, 'notes')"
              />
            </template>
            <template #[`item.id`]="{item}">
              <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                  <AppButton class="ml-2" variation="icon" v-on="on">
                    <i class="fal fa-ellipsis-h" />
                  </AppButton>
                </template>
                <v-list class="py-0">
                  <v-list-item>
                    <v-list-item-title class="aon-teal--text px-0">
                      <AppButton
                        style="min-width: 100%"
                        variation="ghost"
                        @click="openDeleteOpportunity(item)"
                        >Delete Opportunity</AppButton
                      >
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>

    <CpiOpportunityModal
      :key="showAddOpportunity"
      :open.sync="showAddOpportunity"
    />
    <FreeTextModal
      v-if="showFreeTextModal"
      :cpi-opportunity="modifiedOpportunity"
      :field-name="editOpportunityFieldName"
      :open.sync="showFreeTextModal"
      @updated="getCpiOpportunities"
    />
    <v-slide-y-reverse-transition>
      <v-card
        v-show="!!selectedOpportunities.length"
        style="transform: translateX(-50%);left: 50%;position: fixed;bottom: 40px;"
        elevation="3"
        outlined
      >
        <v-card-actions>
          <AppButton
            :disabled="!selectedOpportunities.length"
            class="mr-2"
            href="/cpi-presentation"
            target="_blank"
            append-icon="fa fa-external-link"
          >
            Presentation View
          </AppButton>
          <AppButton
            :disabled="!selectedOpportunities.length"
            class="mr-2"
            target="_blank"
            append-icon="fa fa-external-link"
            @click="exportRecords"
          >
            Export Records
          </AppButton>
          <AppButton
            variation="ghost"
            @click="() => $store.commit('cpiOpportunities/clearSelected')"
          >
            Clear Selection
          </AppButton>
        </v-card-actions>
      </v-card>
    </v-slide-y-reverse-transition>
    <Spinner v-if="loading" class="absolute-center" />
    <v-dialog
      v-model="showDeleteOpportunity"
      class="update-security-question-dialog"
      width="350"
      persistent
      scrollable
    >
      <Card headline="Delete Opportunity">
        <v-row>
          <v-col>Are you sure you want to delete this record?</v-col>
        </v-row>
        <v-row>
          <v-col
            >Borrower name: {{ recordToDelete.borrowerName || 'None' }}</v-col
          >
        </v-row>
        <v-row class="mt-4">
          <AppButton
            class="mx-auto"
            :disabled="loading"
            variation="ghost"
            @click="closeDeleteOpportunity()"
          >
            Cancel
          </AppButton>
          <AppButton
            class="mx-auto"
            :loading="loading"
            variation="prominent"
            @click="deleteCpiOpportunity()"
          >
            Delete
          </AppButton>
        </v-row>
      </Card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TableFiltersSelect from '@aon/cfs-components/src/components/DataTables/TableFiltersSelect';
import CpiOpportunityModal from '@/components/CpiOpportunity/CpiOpportunityModal';
import FreeTextModal from '@/components/CpiOpportunity/FreeTextModal';
import {
  deleteCpiOpportunity,
  listCpiOpportunities,
} from '@/api/cpiOpportunities';
import { ExportToCsv } from 'export-to-csv';

export default {
  name: 'CpiOpportunityList',
  components: { FreeTextModal, CpiOpportunityModal, TableFiltersSelect },
  data() {
    let filters = localStorage.getItem('cpiCurrentFilters');
    if (filters) filters = JSON.parse(filters);
    let options = localStorage.getItem('cpiPagingOptions');
    if (options) options = JSON.parse(options);

    return {
      cpiOpportunities: [],
      showAddOpportunity: false,
      showFreeTextModal: false,
      showDeleteOpportunity: false,
      recordToDelete: {},
      activeFilters: [],
      currentFilters: filters || {},
      loading: false,
      pagingOptions: options || { itemsPerPage: 100 },
      pageItemsCount: 0,
      modifiedOpportunity: {},
      editOpportunityFieldName: '',
      cpiOpportunityListHeaders: [
        {
          sortable: false,
          value: 'selected',
        },
        {
          value: 'borrowerName',
          text: this.$t('cpiOpportunityList.headers.borrowerName'),
          width: '10%',
        },
        {
          value: 'lenderName',
          text: this.$t('cpiOpportunityList.headers.lenderName'),
          width: '8%',
        },
        {
          value: 'targetCloseDate',
          text: this.$t('cpiOpportunityList.headers.targetCloseDate'),
          width: '8%',
        },
        {
          value: 'result',
          text: this.$t('cpiOpportunityList.headers.result'),
          width: '8%',
        },
        {
          sortable: false,
          value: 'loanAmount',
          text: this.$t('cpiOpportunityList.headers.loanAmount'),
          width: '12%',
        },
        {
          value: 'valuationPriority',
          text: this.$t('cpiOpportunityList.headers.valuationPriority'),
        },
        {
          sortable: false,
          value: 'creditAnalysis',
          text: this.$t('cpiOpportunityList.headers.creditAnalysis'),
        },
        {
          sortable: false,
          value: 'patentAnalysis',
          text: this.$t('cpiOpportunityList.headers.patentAnalysis'),
        },
        {
          sortable: false,
          value: 'nextSteps',
          text: this.$t('cpiOpportunityList.headers.nextSteps'),
          width: '20%',
        },
        {
          sortable: false,
          value: 'notes',
          text: this.$t('cpiOpportunityList.headers.notes'),
          width: '20%',
        },
        {
          sortable: false,
          value: 'id',
          text: 'Options',
        },
      ],
      filterTypes: [
        {
          typeText: this.$t('cpiOpportunityList.filters.status.title'),
          typeValue: 'status',
          multiple: true,
          values: [
            {
              text: this.$t('cpiOpportunityList.filters.status.open'),
              value: 'open',
            },
            {
              text: this.$t('cpiOpportunityList.filters.status.closed'),
              value: 'closed',
            },
          ],
        },
        {
          typeText: this.$t('cpiOpportunityList.filters.result.title'),
          typeValue: 'result',
          multiple: true,
          values: [
            {
              text: this.$t('cpiOpportunityList.filters.result.early'),
              value: 'early',
            },
            {
              text: this.$t('cpiOpportunityList.filters.result.icDiligence'),
              value: 'icDiligence',
            },
            {
              text: this.$t(
                'cpiOpportunityList.filters.result.termSheetIssued'
              ),
              value: 'termSheetIssued',
            },
            {
              text: this.$t(
                'cpiOpportunityList.filters.result.termSheetSigned'
              ),
              value: 'termSheetSigned',
            },
            {
              text: this.$t('cpiOpportunityList.filters.result.lenderDeclined'),
              value: 'lenderDeclined',
            },
            {
              text: this.$t(
                'cpiOpportunityList.filters.result.borrowerDeclined'
              ),
              value: 'borrowerDeclined',
            },
            {
              text: this.$t('cpiOpportunityList.filters.result.loanIssued'),
              value: 'loanIssued',
            },
          ],
        },
        {
          typeText: this.$t('cpiOpportunityList.filters.lender.title'),
          typeValue: 'lenderName',
          multiple: true,
          values: [
            {
              text: this.$t('cpiOpportunityList.filters.lender.aon'),
              value: 'Aon Fund',
            },
            {
              text: this.$t('cpiOpportunityList.filters.lender.atlas'),
              value: 'Atlas',
            },
            {
              text: this.$t('cpiOpportunityList.filters.lender.bain'),
              value: 'Bain',
            },
            {
              text: this.$t('cpiOpportunityList.filters.lender.jefferies'),
              value: 'Jefferies',
            },
            {
              text: this.$t('cpiOpportunityList.filters.lender.other'),
              value: 'Other',
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState('cpiOpportunities', {
      selectedOpportunities: state => state.selected,
    }),
    allSelected() {
      if (
        this.selectedOpportunities.length < 1 ||
        this.cpiOpportunities.length < 1
      ) {
        return false;
      }
      return this.cpiOpportunities.every(s =>
        this.selectedOpportunities.some(o => o.id === s.id)
      );
    },
  },
  watch: {
    pagingOptions: {
      handler() {
        this.getCpiOpportunities();
      },
      deep: true,
    },
  },
  methods: {
    openDeleteOpportunity(item) {
      this.recordToDelete = item;
      this.showDeleteOpportunity = true;
    },
    closeDeleteOpportunity() {
      this.showDeleteOpportunity = false;
      this.recordToDelete = {};
    },
    async deleteCpiOpportunity() {
      this.loading = true;
      await deleteCpiOpportunity(this.recordToDelete.id);
      this.loading = false;
      this.recordToDelete = {};
      this.showDeleteOpportunity = false;
      await this.getCpiOpportunities();
    },
    getLoanAmount(item) {
      let display = '';
      if (item.minLoanAmount && !item.maxLoanAmount) {
        display = '';
      } else if (item.minLoanAmount && item.maxLoanAmount) {
        display = `${item.minLoanAmount}M - ${item.maxLoanAmount}M`;
      } else if (item.maxLoanAmount) {
        display = `${item.maxLoanAmount}M` || '';
      }
      return display;
    },
    exportRecords() {
      const keyMap = {
        borrowerName: 'Borrower',
        lenderName: 'Lender',
        patentAnalysis: 'Patent Analysis',
        result: 'Opportunity Status',
        targetCloseDate: 'Target Close Date',
        loanAmount: 'Loan Amount',
        notes: 'Notes',
      };
      const csvExporterOptions = {
        fieldSeparator: ',',
        filename: 'ExportedOpportunities',
        quoteStrings: '"',
        showLabels: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: Object.values(keyMap),
      };
      const csvExporter = new ExportToCsv(csvExporterOptions);
      const csvArray = this.selectedOpportunities.map(op => {
        const item = {};
        Object.keys(keyMap).forEach(key => {
          if (key === 'lenderName' && op[key] === 'Other') {
            item[key] = op.otherLenderName;
          } else if (key === 'result') {
            item[key] = this.$t(`cpiOpportunityList.result.${op.result}`);
          } else {
            item[key] = op[key] || '';
          }
        });
        return item;
      });
      csvExporter.generateCsv(csvArray);
    },
    applyFilters(filters = {}) {
      this.currentFilters = filters;
      this.getCpiOpportunities();
    },
    openFreeTextModal(item, field) {
      this.modifiedOpportunity = item;
      this.editOpportunityFieldName = field;
      this.showFreeTextModal = true;
    },
    getStatusColor(opportunity) {
      return opportunity.status === 'open' ? 'success' : 'info';
    },
    async getCpiOpportunities() {
      this.loading = true;
      try {
        const queryParams = {
          page: this.pagingOptions.page,
          pageSize: this.pagingOptions.itemsPerPage,
          ...this.currentFilters,
          orderBy: this.pagingOptions.sortBy,
          orderByDesc: this.pagingOptions.sortDesc,
        };
        const { data } = await listCpiOpportunities(queryParams);
        this.pageItemsCount = data.rowCount;
        this.cpiOpportunities = data.results;
        localStorage.setItem(
          'cpiCurrentFilters',
          JSON.stringify(this.currentFilters)
        );
        localStorage.setItem(
          'cpiPagingOptions',
          JSON.stringify(this.pagingOptions)
        );
      } catch (error) {
        this.$AppEventBus.$emit('snacktime', {
          type: 'error',
          message: this.$t('errors.unknown'),
        });
      } finally {
        this.loading = false;
      }
    },
    toggleSelectAll() {
      let list;
      if (this.allSelected) {
        list = this.selectedOpportunities.filter(
          s => !this.cpiOpportunities.some(o => o.id === s.id)
        );
      } else {
        list = [...this.selectedOpportunities];
        for (const o of this.cpiOpportunities) {
          if (!this.selectedOpportunities.some(s => s.id === o.id)) {
            list.push(o);
          }
        }
      }
      this.$store.commit('cpiOpportunities/setSelected', list);
    },
  },
};
</script>

<style lang="scss">
.cpi-opportunity-list {
  .v-data-table {
    border-radius: $sheet-border-radius;
    border: $border-gray-05;

    .left-chip {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;
    }

    .v-data-table-header tr {
      height: 64px;

      th {
        &:not(:last-child) {
          border-right: $border-gray-05;
        }

        span {
          color: $aon-gray-01;
          font-size: 1rem;
          font-weight: 700;
        }
      }
    }
  }

  .table-date-item {
    color: $aon-gray-02;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr {
    &:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
      background: $aon-gray-08;
    }

    td:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;
    }
  }

  .v-chip {
    &.draft-chip,
    &.ip-review-chip,
    &.deal-prioritization-chip {
      background: $aon-teal-light !important;
      color: $aon-teal;

      .v-icon {
        color: $aon-teal;
      }
    }
    &.adequate-chip,
    &.\32-chip {
      background: $warning-light !important;
      color: $warning-dark;

      .v-icon {
        color: $warning-dark;
      }
    }
    &.approved-chip,
    &.excellent-chip,
    &.\31-chip {
      background: $success-light !important;
      color: $success-dark;

      .v-icon {
        color: $success-dark;
      }
    }
    &.rejected-chip,
    &.poor-chip,
    &.\33-chip,
    &.\34-chip {
      background: $error-light !important;
      color: $error-dark;

      .v-icon {
        color: $error-dark;
      }
    }
  }

  .applicant-name {
    font-size: 1rem;
  }

  .hide-scrollbar textarea {
    overflow-y: hidden;
  }
}
.v-menu__content {
  width: 250px !important;
}
.grid-filters-menu .filter-list .filter-list-value.active {
  background-color: $aon-gray-06;
}

.cpi-opportunity-list {
  [class$='-chip'] {
    color: white;
    background: $aon-teal !important;
  }

  .vendor-list-select {
    width: 115px;
    color: $aon-peacock;

    .v-select__selections {
      color: $aon-peacock;
    }

    .v-input__slot {
      min-height: 2.75rem;
      color: $aon-peacock;

      &:hover {
        fieldset {
          border-width: 2px;
        }
      }

      fieldset {
        border-color: $aon-peacock;
      }
    }

    input::placeholder {
      color: $aon-peacock;
    }

    i {
      font-size: 1.25em;
      color: $aon-peacock;
    }
  }

  .v-pagination {
    background: $white;
    border: $border-gray-05;
    border-top: 0;
    padding: 1.25rem;
    border-radius: 0 0 8px 8px;

    .v-pagination__navigation {
      background: none;
      box-shadow: none;
    }

    i {
      color: $aon-teal;
    }

    .v-pagination__item {
      background: none;
      border-radius: 50%;
      color: $aon-teal !important;
      box-shadow: none;

      &--active {
        background: $aon-teal-lightest !important;
        color: $aon-teal !important;
      }
    }
  }

  .vendor-list-table {
    border: $border-gray-05;
    overflow: hidden;
    color: $aon-gray-02;
    border-radius: 8px 8px 0 0 !important;
  }

  .vendor-name {
    color: $aon-gray-01;

    .fa-angle-right {
      transition: transform 200ms;
      color: $aon-teal;
      font-size: 1.33em;
    }
  }

  .vendor-search {
    max-width: 14rem;
    .fa-times-circle,
    .fa-search {
      font-size: 1.1em;
      font-weight: 500;

      &:hover {
        color: $aon-peacock;
      }
    }
  }

  .cpi-table-container {
    background: none;
    border-radius: 8px;
  }

  .theme--light.v-data-table {
    border-radius: 8px;

    .v-data-table__wrapper {
      .v-data-table-header {
        background-color: $white;

        .v-data-table-header__icon {
          opacity: 1;
          margin-left: 8px;
          font-size: 1rem !important;

          &:before {
            content: '\f077';
          }
        }
      }

      > table {
        > tbody {
          tr:not(:last-child) td:not(.v-data-table__mobile-row) {
            border-color: $border-gray-05;
          }

          > tr {
            cursor: pointer;

            td {
              font-size: 1rem;
              height: 56px;
            }

            &:hover {
              &:not(.v-data-table__expanded__content) {
                &:not(.v-data-table__empty-wrapper) {
                  background: $aon-gray-08;
                }
              }
            }
          }
        }

        > thead {
          > tr {
            &:last-child {
              > th {
                font-size: 1rem;
                height: 4rem;
                background: $white;
                color: $aon-gray-01;
                border-bottom: $border-gray-05;
                border-right: $border-gray-05;

                &:last-child {
                  border-right: none;
                }

                &.active {
                  box-shadow: inset 0px -4px 0 0px $aon-peacock;
                  color: $aon-peacock;

                  i {
                    color: $aon-peacock;
                  }
                }
              }
            }
          }
        }
      }
    }

    .v-data-table__expanded {
      &.v-data-table__expanded__row {
        background: $aon-gray-08;

        .text-start {
          border-bottom: 0;
        }

        .fa-angle-right {
          transform: rotate(90deg);
        }
      }

      &.v-data-table__expanded__content {
        background: $aon-gray-08;
        box-shadow: none;
      }
    }
  }

  .item-status {
    &.theme--light {
      color: $aon-gray-02;
      background: none;

      &.compliant {
        color: $success-dark;
      }

      &.pending-registration,
      &.inactive {
        color: $aon-gray-02;
      }

      &.pending-contract-acceptance {
        color: $aon-teal-dark;
      }

      &.non-compliant-insurance {
        color: $error-dark;
      }
    }
  }
}

.cpi-opportunity-list.container.full-width.route {
  max-width: none;
  padding: 0 32px;
}

.header-nav .container {
  max-width: none;
}
</style>
