<template>
  <v-dialog v-model="open" width="300" persistent>
    <div class="cpi-opportunity-modal">
      <Card>
        <div class="d-flex align-center">
          <h4>{{ $t('cpiOpportunity.cpiOpportunity') }}</h4>
          <AppButton variation="icon" @click="closeDialog">
            <i class="fal fa-times" />
          </AppButton>
        </div>
        <v-form @submit.prevent="submit">
          <v-text-field
            v-model="borrowerName"
            :label="$t('cpiOpportunity.form.borrowerName')"
          />
          <v-radio-group
            v-model="lenderDriven"
            mandatory
            :label="$t('cpiOpportunity.form.lenderDriven')"
          >
            <v-radio label="Yes" :value="true"></v-radio>
            <v-radio label="No" :value="false"></v-radio>
          </v-radio-group>
          <v-select
            v-if="lenderDriven"
            v-model="lenderName"
            :items="existingLenders"
            label="Lenders"
            dense
            solo
          />
          <v-text-field
            v-if="lenderDriven && lenderName === 'Other'"
            v-model="customLender"
            :label="$t('cpiOpportunity.form.lenderName')"
          />
          <AppButton
            :disabled="!(borrowerName || '').trim()"
            :loading="saving"
            @click="createOpportunity"
          >
            {{ $t('cpiOpportunity.form.createOpportunity') }}
          </AppButton>
        </v-form>
      </Card>
    </div>
  </v-dialog>
</template>

<script>
import { createCpiOpportunity } from '@/api/cpiOpportunities';

export default {
  name: 'CpiOpportunityModal',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      existingLenders: ['Aon Fund', 'Atlas', 'Bain', 'Jefferies', 'Other'],
      lenderDriven: false,
      lenderName: null,
      customLender: null,
      borrowerName: null,
      saving: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit('update:open', false);
    },
    async createOpportunity() {
      this.saving = true;
      try {
        const newOpportunity = {
          borrowerName: this.borrowerName,
          lenderName: 'Aon Fund',
          status: 'open',
          result: 'early',
        };
        if (this.lenderDriven) {
          newOpportunity.lenderName = this.lenderName;
          newOpportunity.otherLenderName = this.customLender;
        }

        const { data } = await createCpiOpportunity(newOpportunity);
        await this.$router.push(`cpi-opportunities/${data.id}`);
      } catch {
        this.$AppEventBus.$emit('snacktime', {
          type: 'error',
          message: 'Unable to create new Cpi Opportunity',
        });
      }
      this.saving = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cpi-opportunity-modal {
  .exit-button {
    cursor: pointer;
  }
}
</style>
